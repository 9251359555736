.faq-section {
    max-width: 1200px;
    margin: 30px auto;
    padding: 20px;
    border-radius: 16px;
    background-color: #ffffff; /* Light background for contrast */
    box-shadow: 0 4px 8px rgba(0,0,0,0.1); /* Subtle shadow for depth */
}

.faq-section h2 {
    font-weight: bold;
}

.faq-item {
    border-bottom: 1px solid #ccc; /* Separator for each item */
    padding: 1rem .6rem; 
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.faq-item:last-child {
    border-bottom: none; /* Remove border for the last item */
}

.faq-question {
    display: flex;
    justify-content: space-between; /* Space between question text and icon */
    align-items: center;
    font-size: 18px; /* Larger font for readability */
    font-weight: 500; /* Medium font weight for the question */
}

.faq-answer {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease-out;
    margin: 5px 0 0; /* Margin top for spacing */
    padding: 0 0px; /* Padding for text alignment */
}

.faq-item.active .faq-answer {
    padding-top: 1rem;
    max-height: 200px; /* Adjust based on content size */
}

.faq-item:hover {
    border-radius: 12px;
    background-color: #f0f0f0; /* Hover effect for interactive feedback */
}
  