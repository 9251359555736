.contact-container {
    display: flex;
    flex-direction: row;
    gap: 20px;
    padding: 20px;
    max-width: 2000px;
    margin: auto;
    align-items: flex-start; /* Aligns children at the top */
    min-width: 600px;

}

.contact-tabs {
    display: flex;
    flex-direction: row;
    justify-content: start;
    margin-bottom: 20px; /* Ensures space between tabs and content */
}

.contact-tabs button {
    border: none;
    background-color: white;
    border-radius: 10px;
}

.tab {
    display: flex;
    align-items: center;
    padding: 10px;
    cursor: pointer;
    background-color: #f0f0f0;
    border: 1px solid #ccc;
    transition: background-color 0.3s, transform 0.3s;
    margin-right: 5px;
}

.tab.active {
    border: 2px solid #007bff;
    color: black;
    transform: translateY(-2px);
}

.active-content {
    display: flex;
    flex-direction: column;
    background-color: #fff;
    box-shadow: 0 2px 10px rgba(0,0,0,0.1);
    padding: 20px;
    min-height: 600px;
    border-radius: 20px;
    width: 100%;
}

.calendly-embed, .contact-form {
    width: 100%; /* Ensures full width usage */
}

.contact-info {
    padding: 20px;
    background-color: #fff;
    box-shadow: 0 2px 10px rgba(0,0,0,0.1);
    flex: 1;
    border-radius: 20px;
}

.contact-info h2 {
    font-weight: bold;
}

.info-group {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
}

.info-group svg {
    margin-right: 10px;
    font-size: 24px;
}

.contact-form form {
    display: flex;
    flex-direction: column;
}

.contact-form label {
    margin-bottom: 5px;
}

.contact-form input[type="text"],
.contact-form input[type="email"],
.contact-form textarea {
    padding: 8px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 6px;
}

.contact-form button {
    padding: 10px 15px;
    background-color: #007bff;
    color: white;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s;
    border-radius: 4px;
}

.contact-form button:hover {
    border: 2px solid #0056b3;
}

.form-group {
    position: relative;
    margin-bottom: 20px;
}

input[type="text"], input[type="email"], textarea {
    width: 100%;
    padding: 10px 10px 10px 5px;
    display: block;
    border: none;
    border-bottom: 2px solid #ccc;
    background-color: transparent;
    font-size: 16px;
}

input[type="text"]:focus, input[type="email"]:focus, textarea:focus {
    outline: none;
    border-bottom-color: #007bff;
}

input[type="text"]:focus + label, input[type="email"]:focus + label, textarea:focus + label,
input[type="text"]:not(:placeholder-shown) + label,
input[type="email"]:not(:placeholder-shown) + label,
textarea:not(:placeholder-shown) + label {
    top: -20px;
    font-size: 12px;
    color: #007bff;
}

label {
    color: #999;
    font-size: 16px;
    font-weight: normal;
    position: absolute;
    pointer-events: none;
    left: 5px;
    top: 10px;
    transition: 0.2s ease all;
    -moz-transition: 0.2s ease all;
    -webkit-transition: 0.2s ease all;
}

textarea {
    height: 80px;
    resize: vertical;
}


@media (max-width: 768px) {
    .contact-container {
        flex-direction: column;
        min-width: 100vw;
    }

    .contact-tabs {
        flex-direction: column;
    }

    .active-content, .contact-info {
        width: 100%; /* Full width on mobile */
    }
}
