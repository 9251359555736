.homepage {
    width: 100%;
    text-align: center;
    overflow-x: hidden; /* Prevent horizontal scroll */
}

.hero-section {
    background: linear-gradient(to right, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('../../assets/img/bannerInternone.jpeg') no-repeat center center/cover;
    height: 110vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    text-shadow: 0 2px 4px rgba(0,0,0,0.5);
    position: relative;
    top: -70px;
    margin-bottom: -70px;
    padding: 20px;
    width: 100%; /* Ensure it takes full width */
}

.cta-button {
    background-color: var(--primary-button);
    color: white;
    border: none;
    padding: 15px 30px;
    font-size: 1.2rem;
    margin-top: 20px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.cta-button:hover {
    background-color: darken(var(--primary-button), 10%);
}

.about-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    background: linear-gradient(135deg, rgb(222, 235, 253) 0%, #ADD8E6 100%);
    padding: 40px 20px;
    margin: 20px auto;
    max-width: 90%;
    color: white;
    border-radius: 10px;
    width: 100%;
}

.about-content h2 {
    font-size: 2.5rem;
    color: #004aad;
    margin-bottom: 20px;
}

.about-content p {
    font-size: 1.2rem;
    color: #333;
    margin-top: 10px;
    line-height: 1.6;
    max-width: 90%;
}

.about-highlights {
    display: flex;
    flex-wrap: wrap; /* Allow wrapping for small screens */
    justify-content: center;
    margin-top: 20px;
    gap: 20px;
}

.highlight {
    text-align: center;
    flex: 1;
    min-width: 120px;
}

.services-section {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
    gap: 20px;
    padding: 40px 20px;
    background-color: #f4f7fa;
    color: #333;
    border-radius: 20px;
    width: 100%;
}

.service {
    background-color: white;
    padding: 20px;
    box-shadow: 0 2px 5px rgba(0,0,0,0.1);
    border-radius: 12px;
    display: flex;
    flex-direction: column;
}

.service h3 {
    color: #0078D7;
    margin-bottom: 10px;
}

.service p {
    font-size: 1rem;
    line-height: 1.6;
    flex-grow: 1;
}

.service-button {
    background-color: var(--primary-button);
    color: white;
    border: none;
    padding: 10px 20px;
    font-size: 1rem;
    margin-top: 10px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.service-button:hover {
    background-color: darken(var(--primary-button), 10%);
}

.service:hover {
    transform: translateY(-5px);
    box-shadow: 0 4px 20px rgba(0,0,0,0.2);
}

.testimonials-section {
    background: #fff;
    padding: 40px 20px;
    margin: 20px 0;
    width: 100%;
}

.testimonials-slider {
    display: flex;
    overflow: auto;
    scroll-snap-type: x mandatory;
    width: 100%;
}

.testimonial {
    flex: 0 0 80%;
    margin: 0 10%;
    background: #f9f9f9;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0,0,0,0.1);
    scroll-snap-align: center;
}

.testimonial h4 {
    margin-top: 10px;
    font-weight: bold;
}

@media (max-width: 768px) {
    .about-content {
        padding: 20px 10px;
        margin: 20px;
        max-width: 90%;
    }

    .about-content h2 {
        font-size: 2rem;
    }

    .about-content p {
        font-size: 1rem;
    }

    .highlight {
        width: 80px; /* Adjust for smaller screens */
    }
}

@media (max-width: 992px) {
    .hero-section {
        height: 80vh;
    }
}
