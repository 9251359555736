.footer {
    background-color: var(--text-secondary);
    color: white;
    padding: 40px 20px;  
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20vh;
}

.footer-content {
    display: flex;
    flex-direction: row; 
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 1200px; 
    margin: 0 auto;
}

.footer-section {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.footer-section p {
    font-weight: bolder;
}

.footer-logo a {
    font-size: 1.5em;
    color: #fff;
    font-weight: bold;
    text-decoration: none;
}

.footer-logo p {
    margin-top: 10px;
}

.footer-logo span {
    color: var(--primary-color);
}

.footer a {
    color: #fff;
    text-decoration: none;
    margin: 0 10px;
}

.footer a:hover {
    text-decoration: underline;
}

footer img {
    margin-top: 20px;
    width: 150px; 
}

@media (max-width: 768px) {
    .footer-content {
        flex-direction: column;
        padding: 20px;
    }

    .footer-section {
        margin-bottom: 30px; 
    }
}
  