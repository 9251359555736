:root {
  --background: #F8F8FF;
  --text-primary: black;
  --text-secondary: #A9A9A9;
  --primary-color: #0078D7;
  --primary-button: #007aff;
  --secondary-color: #8c4684;
  --accent:  #967BB6;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--background);
  margin: 0;
  padding: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  display: grid;
  grid-template-rows: auto 1fr auto; /* Header, content, footer */
}

.site-container {
  display: grid;
  grid-template-rows: auto 1fr auto; /* Navbar, content, footer */
  min-height: 100vh; /* Full viewport height */
}

.content {
  /* Ensures content expands to fill available space, pushing the footer down */
  flex-grow: 1; 
}

.banner-image {
  width: 100%;
  height: 200px;
  background: linear-gradient(135deg, #5B9BD5 0%, #ADD8E6 100%);
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  position: relative; 
  margin-top: -70px; 
  padding-top: 70px; 
}

.banner-image::before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100px;
  background: rgba(255, 255, 255, 0.2);
  clip-path: polygon(0 100%, 100% 0, 100% 100%, 0% 100%); 
}

/* .banner-image::after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  width: 200px;
  height: 200px;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 50%; 
  transform: translate(50%, -50%); 
} */
