.achievements-page {
    text-align: center;
}

.achievement-title {
    font-size: 2.5rem;
    color: #004aad;
    padding: 10px;
    background-color: #f4f7fa;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    margin-bottom: 20px;
}

.achievements-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 20px;
    padding: 20px;
}

.achievement-item {
    position: relative;
    cursor: pointer;
    overflow: hidden;
    border-radius: 8px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.achievement-item img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 8px;
    transition: transform 0.3s ease;
}

.achievement-item:hover img {
    transform: scale(1.05);
}

.company-name-overlay {
    position: absolute;
    bottom: 0;
    width: 100%;
    background: rgba(0, 0, 0, 0.6);
    color: white;
    padding: 10px 0;
    text-align: center;
    transition: background 0.3s ease;
}

.achievement-item:hover .company-name-overlay {
    background: rgba(0, 0, 0, 0.8);
}

.achievement-detail-modal-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10000;
}

.achievement-detail-modal {
    background: rgba(255, 255, 255, 0.95);
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    overflow-y: auto;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-height: 90vh;
    max-width: 90%;
    position: relative;
    animation: fadeIn 0.3s;
}

.modal-image {
    max-width: 100%;
    margin: 10px 0;
}

.close-button {
    align-self: flex-end;
    padding: 5px 10px;
    cursor: pointer;
    background: #0078D7;
    color: white;
    border: none;
    border-radius: 5px;
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.2rem;
}

.achievement-detail-modal h2 {
    margin-bottom: 20px;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@media (max-width: 768px) {
    .achievement-title {
        font-size: 1.8rem;
        padding: 5px;
    }

    .achievement-detail-modal {
        width: 95%;
        height: 95%;
        max-width: none;
        max-height: none;
        border-radius: 10px;
        padding: 10px;
    }

    .close-button {
        position: absolute;
        top: 10px;
        right: 10px;
    }

    .modal-image {
        height: auto;
    }
}
