.plans-page {
    display: flex;
    align-items: center;
    flex-direction: column;
    box-sizing: border-box;
}

.plans-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 1200px;
    padding: 20px;
    box-sizing: border-box;
}

.plans-header {
    margin-bottom: 20px;
    font-size: 2rem;
    color: #004aad;
}

.plans-image {
    width: 100%;
    height: auto;
    border-radius: 30px;
    max-width: 1000px;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
}

.loading-state {
    font-size: 16px;
    color: #666;
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .plans-header {
        font-size: 1.5rem;
    }

    .plans-image {
        width: 95%;
        border-radius: 20px;
    }

    .plans-container {
        padding: 10px;
    }
}
