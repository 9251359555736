.navbar {
    transition: background-color 0.3s ease-in-out, backdrop-filter 0.3s ease-in-out;
    background-color: transparent !important;
    display: flex !important;
    justify-content: space-between !important;
    align-items: center !important;
    flex-direction: row !important;
    width: 100% !important;
}

.navbar.scrolled {
    background-color: rgba(0, 0, 0, 0.3) !important;
    backdrop-filter: blur(8px) !important;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1) !important;
    color: black !important;
}

.navbar-brand {
    flex-grow: 1 !important;
    font-size: 1.5rem !important;
    font-weight: bold;
    color: white !important;
}

.navbar-brand span {
    color: var(--primary-color);
}

.nav-link {
    position: relative;
    transition: color 0.3s;
    margin-left: 2rem !important; /* Adjusted for better control */
    font-size: 1.1rem !important;
    color: white !important;
    font-weight: 550 !important;
}

.nav {
    justify-content: flex-end !important;
}

.nav-link::after {
    content: '';
    position: absolute;
    width: 0;
    height: 2px;
    background-color: var(--primary-button);
    bottom: -3px;
    left: 0;
    transition: width 0.3s;
}

.nav-link:hover, .nav-link:focus {
    color: var(--primary-button) !important;
}

.nav-link:hover::after, .nav-link:focus::after {
    width: 100% !important;
}

.navbar-collapse {
    justify-content: flex-end !important;
}

.container, .container-fluid {
    padding-right: 10rem !important; /* Reduced for better responsiveness */
    padding-left: 10rem !important;
}

.language-toggle {
    display: flex !important;
    align-items: center !important;
    margin-left: 2rem !important;
    font-size: 16px; /* Ensure font size is appropriate */
}

.language-toggle .nav-link {
    padding: 20px !important; /* Ensures tight padding around links */
    margin: 0px !important;
}

@media (max-width: 992px) {
    .navbar {
        transition: background-color 0.3s ease-in-out, backdrop-filter 0.3s ease-in-out;
        background-color: transparent !important;
        display: flex !important;
        flex-direction: row !important;
        width: 100% !important;
        padding: 0px !important;
        margin: 0px !important;
    } 
    .navbar-toggle {
        background-color: transparent; /* To make the toggle blend or stand out */
        border: none; /* Removes border if you prefer a cleaner look */
        position: relative; /* Ensures it's properly positioned */
        z-index: 5; /* Keeps it above other content */
    }

    .nav-link , .language-toggle {
        margin-left: 0px !important;
    }

    .navbar-collapse {
        background-color: rgba(0,0,0,0.5); /* Adds a subtle background for better readability */
        box-shadow: 0 4px 8px rgba(0,0,0,0.1); /* Optional: adds shadow for depth */
        border-radius: 20px;
    }

    .language-toggle {
        flex-direction: column; /* Stacks the language options vertically */
        align-items: center; /* Centers the language links */
        margin-top: 1rem; /* Adds space above the language toggle for separation */
    }

    .container, .container-fluid {
        padding-right: 1rem; /* Right padding */
        padding-left: 1rem; /* Left padding */
        max-width: 100%; /* Ensures the container uses all available width */
        padding-right: 1rem !important; /* Reduced for better responsiveness */
        padding-left: 1rem !important;
    }

    .nav-link {
        padding: 0.5rem 1rem; /* Increases touch target size */
        text-align: center; /* Aligns text centrally for a neater appearance */
        width: 100%; /* Ensures nav links use the full width of their container */
        border-bottom: 1px solid #eee; /* Adds a subtle separator between items */
    }

    .nav-item:last-child .nav-link {
        border-bottom: none; /* Removes border from the last item */
    }
}
